import $ from "jquery";
import Backbone from "backbone";

const EVENTS = {
    SYNC: "SYNC",
};

function countPercentage(count, totalCount) {
    if (totalCount === 0) {
        return 0;
    }
    return (count * 100) / totalCount;
}

const ProgressModel = Backbone.Model.extend(
    {
        initialize(attributes, options) {
            this.count = 0;
            this.totalIncrements = options.totalIncrements;
        },
        increment() {
            ++this.count;
            this.trigger(EVENTS.SYNC, {
                percentage: countPercentage(this.count, this.totalIncrements),
                totalCount: this.totalIncrements,
                count: this.count,
            });
        },
    },
    {
        EVENTS,
    }
);

ProgressModel.create = function (totalIncrements) {
    return new ProgressModel(null, {
        totalIncrements,
    });
};

export default ProgressModel;
