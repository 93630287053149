import _ from "underscore";
import Backbone from "backbone";
import app from "src/shared/app";
import omissible from "src/shared/api/omissible";

const isExtoleUser = (user) => user.email?.endsWith("@extole.com"); // it's a temporary solution - see https://extole.atlassian.net/browse/ENG-17013?focusedCommentId=134656
const pickAttributes = ["email", "password", "first_name", "last_name"];
const UserModel = Backbone.Model.extend({
    idAttribute: "user_id",
    urlRoot: "/api/v2/users",
    defaults: {
        is_me: false,
        first_name: null,
        last_name: null,
        full_name: null,
    },
    parse(response) {
        if (response.user_id === app.getSession().getUserId()) {
            response.is_me = true;
        } else {
            response.is_me = false;
        }

        if (response.first_name) {
            response.full_name = response.first_name;
        }
        if (response.last_name) {
            response.full_name += ` ${response.last_name}`;
        }
        response.is_extole_user = isExtoleUser(response);
        return response;
    },
    sync(method, model, options) {
        const request = _.pick(this.attributes, pickAttributes);
        if (method === "update") {
            omissible.parseAttributes(request, pickAttributes);
            options.attrs = request;
        }
        if (method === "create") {
            options.attrs = _.pick(this.attributes, "email");
        }
        return Backbone.sync.call(this, method, model, options);
    },
    validate(attributes, options) {
        if (!attributes.email) {
            return {
                code: "email_required",
                message: "Required",
                parameters: {},
            };
        }
    },
    sendResetPasswordEmail() {
        const options = {
            type: "POST",
            url: `${this.urlRoot}/password-reset`,
            contentType: "application/json",
            data: JSON.stringify({
                email: this.get("email"),
            }),
        };
        _.extend(options);
        return Backbone.sync.call(this, null, this, options);
    },
    unlock() {
        const self = this;
        const options = {
            type: "POST",
            url: `${this.url()}/unlock`,
            success() {
                self.set("locked", false);
            },
        };
        _.extend(options);
        return Backbone.sync.call(this, null, this, options);
    },
});

export default UserModel;
