import _ from "underscore";

function omissible(obj, key) {
    if (!_.has(obj, key)) {
        return;
    }
    const value = obj[key];
    if (_.isNull(value) || _.isUndefined(value)) {
        delete obj[key];
    }
}

omissible.optional = (obj, key) => {
    // Omissibles that support optionals allow passing null to clear the property
    if (!_.has(obj, key)) {
        return;
    }
    if (_.isEmpty(obj[key])) {
        obj[key] = null;
    }
};

omissible.parseAttributes = (
    attributes,
    omissibleKeys = [],
    optionalOmissibleKeys = []
) => {
    _.each(optionalOmissibleKeys, (key) => {
        omissible.optional(attributes, key);
    });
    _.each(omissibleKeys, (key) => {
        omissible(attributes, key);
    });
};

export default omissible;
