import $ from "jquery";
import Backbone from "backbone";
import _ from "underscore";
import SubscriptionModel from "src/shared/subscriptions/SubscriptionModel";

const SubscriptionCollection = Backbone.Collection.extend({
    model: SubscriptionModel,

    url() {
        return "api:///v6/subscriptions";
    },
});

export default SubscriptionCollection;
