import $ from "jquery";
import _ from "underscore";
import Backbone from "backbone";

const UserModel = Backbone.Model.extend({
    urlRoot: "api:///v2/users",
    idAttribute: "user_id",
});

UserModel.create = function (userId) {
    return new UserModel({ user_id: userId });
};

export default UserModel;
