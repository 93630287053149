import $ from "jquery";
import _ from "underscore";
import Deferred from "src/shared/Deferred";

function resolve() {
    return $.Deferred().resolve();
}

export default {
    promiseAll(promises) {
        const deferred = $.Deferred();
        const totalCount = promises.length;
        let counter = totalCount;
        const results = [];
        const errors = [];
        if (counter === 0) {
            return deferred.resolve();
        }
        _.each(promises, (promise, index) => {
            promise.call().always((response, result) => {
                if (result === "error") {
                    results[index] = null;
                    errors.push({
                        response,
                        index,
                    });
                } else {
                    results[index] = response;
                }
                if (--counter === 0) {
                    deferred.resolve(results, errors);
                }
            });
        });
        return deferred;
    },

    executeSequentially(promises) {
        const deferred = Deferred.create(promises.length);
        _.reduce(
            promises,
            (previousPromise, promise) =>
                previousPromise.then(() =>
                    promise().then(function () {
                        deferred.getProgressModel().increment();
                        return arguments;
                    })
                ),
            resolve()
        ).done(deferred.resolve);
        return deferred;
    },
};
