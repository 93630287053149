import $ from "jquery";
import _ from "underscore";
import Backbone from "backbone";

const LIMIT = 10;

export default {
    initialize(options) {
        this.offset = 0;
        this.limit = options?.limit || LIMIT;
    },
    sync(method, model, options) {
        if (method !== "read") {
            throw new Error(`Unsupported operation: ${method}`);
        }
        options.data = options.data || {};
        _.extend(options.data, {
            offset: this.offset,
            limit: this.getLimit(),
        });
        return Backbone.sync.call(this, method, model, options);
    },
    getOffset() {
        return this.offset;
    },
    getLimit() {
        return this.limit;
    },
    fetchPrevious(parameters) {
        if (this.atStart()) {
            return;
        }
        this.offset -= this.getLimit();
        return this.fetch(parameters);
    },
    fetchNext(parameters) {
        if (this.atEnd()) {
            return;
        }
        this.offset += this.getLimit();
        return this.fetch(parameters);
    },
    getLength() {
        return this.length;
    },
    atStart() {
        if (this.offset === 0) {
            return true;
        }
        return false;
    },
    atEnd() {
        if (this.getLength() < this.getLimit()) {
            return true;
        }
        return false;
    },
};
