import _ from "underscore";
import Backbone from "backbone";

const CampaignModel = Backbone.Model.extend({
    idAttribute: "campaign_id",
});

const CampaignCollection = Backbone.Collection.extend({
    url: "/api/v2/campaign-summaries",
    model: CampaignModel,
});

export default CampaignCollection;
