import $ from "jquery";
import _ from "underscore";
import Uri from "jsuri";
import Backbone from "backbone";
import UserModel from "src/shared/models/UserModel";
import Session from "js/Session";

const SCOPES = {
    ALL: "all",
    ACCOUNT: "account",
};

const UserCollection = Backbone.Collection.extend(
    {
        model: UserModel,
        initialize(attributes, options) {
            this.scope = options?.scope;
        },
        url() {
            const uri = new Uri("/api/v2/users");
            const { scope } = this;
            if (scope) {
                uri.addQueryParam("scope", scope);
            }
            return uri.toString();
        },
    },
    {
        SCOPES,
        create: function (scope) {
            return new UserCollection(null, {
                scope,
            });
        },
        fetchAll: function () {
            return UserCollection.create(SCOPES.ALL)
                .fetch()
                .then((users) => users);
        },
        fetchNonSuperUsers() {
            return UserCollection.create(SCOPES.ACCOUNT)
                .fetch()
                .then((users) => users);
        },
        fetchSuperUsers() {
            if (!Session.getInstance().isSuperUser()) {
                return $.Deferred().resolve([]);
            }
            return $.when(
                UserCollection.fetchAll(),
                UserCollection.fetchNonSuperUsers()
            ).then((allUsers, nonSuperUsers) => {
                const nonSupeUserIds = _.pluck(nonSuperUsers, "user_id");
                return _.filter(
                    allUsers,
                    (user) => !nonSupeUserIds.includes(user.user_id)
                );
            });
        },
    }
);

export default UserCollection;
