import $ from "jquery";
import ProgressModel from "src/shared/ProgressModel";

const Deferred = {
    create(totalIncrements) {
        const deferred = $.Deferred();
        const progressModel = ProgressModel.create(totalIncrements);

        deferred.getProgressModel = function () {
            return progressModel;
        };

        return deferred;
    },
};
export default Deferred;
